<script setup lang="ts">
  import {computed} from 'vue';
  import times from 'lodash/times';
  import useCpBem from '~/composables/useCpBem';
  import CpIconStandard from '~/components/cp/CpIcon/CpIconStandard/CpIconStandard.vue';
  import CpPillPdp from '~/components/cp/CpPill/CpPillPdp/CpPillPdp.vue';
  import CpPressable from '~/components/cp/CpPressable/CpPressable.vue';
  import CpText from '~/components/cp/CpText/CpText.vue';

  const props = withDefaults(defineProps<{
    rate: number,
    totalReviews?: number,
    hideCount?: boolean
    onPdp?: boolean,
    disabled?: boolean,
    af?: boolean
  }>(), {
    onPdp: false,
    disabled: false,
    hideCount: false,
    af: false,
  });
  const emit = defineEmits({
    goToReviews: null,
  });

  const allowedRating = Math.max(0, Math.min(props.rate, 5));
  const full = Math.floor(allowedRating);
  const half = Math.floor(allowedRating) !== allowedRating;
  const empty = 5 - full + (half ? -1 : 0);

  const stars = computed(() =>[
      ...times(full, () => 'star'),
      ...times(half ? 1 : 0, () => 'star_half'),
      ...times(empty, () => 'star_border'),
  ]);

  const {b, e, em} = useCpBem('cp-product-rate');

</script>

<template>
    <div :class="b">
        <CpText v-if="onPdp" :class="e('rate-label')" variant="caption-bold" type="mkt-dark">
            {{ rate }}
        </CpText>
        <div :class="e('stars')">
            <CpIconStandard
                v-for="(star, index) in stars"
                :key="index"
                :icon="star"
                :class="em('star', {first: index === 0})"
                :type="disabled ? 'neutral-light': af ? 'warning' : 'secondary'"
                kind="small"
            />
        </div>
        <CpPressable v-if="!hideCount" @click="emit('goToReviews')">
            <CpPillPdp
                kind="reviews"
                border="borderPrimary"
                variant="white"
                label-kind="primary"
                :label="onPdp ? 'opiniones' : undefined"
                :quantity="totalReviews+''"
            />
        </CpPressable>
    </div>
</template>

<style scoped lang="scss">
  .cp-product-rate {
    display: flex;
    align-items: center;
    gap: var(--cp-gutter-xsmall);

    &__stars {
      display: flex;
    }

    &__star {
      width: var(--cp-gutter);
      height: var(--cp-gutter);
      margin-left: -4px;
      &--first {
        margin-left: 0;
      }

      :deep(.cp-icon-standard__font) {
        font-size: 14px !important;
      }
    }
  }
</style>
